/**
 * Inhaltsverzeichnis
 * 	1. Type Check
 * 
 */


/* ================================================== */
/* 1. Type Check */
/* -------------------------------------------------- */
// #=#=#=#=#=# 1.1 is - Object #=#=#=#=#=#
/**
 * is - a Type-match method collection
 */
export const is		= {
	/**
	 * Determins if the given input is set.
	 */
	set(
		obj:any
	): boolean {
		return obj != undefined;
	},

	/**
	 * Determins if the given input is a String.
	 */
	string(
		obj:any
	): boolean {
		return typeof obj === 'string' || obj instanceof String;
	},

	/**
	 * Determins if the given input is a Boolean.
	 */
	bool(
		obj:any
	): boolean {
		return typeof obj === 'boolean' || obj instanceof Boolean;
	},

	/**
	 * Determins if the given input is a Number.
	 */
	number(
		obj:any
	): boolean {
		return !isNaN(obj);
	},

	/**
	 * Determins if the given input is an Integer.
	 */
	int(
		obj:any
	): boolean {
		return Number.isSafeInteger(obj);
	},

	/**
	 * Determins if the given input is a Float.
	 */
	float(
		obj:any
	): boolean {
		return Number(obj) === obj && obj % 1 !== 0;
	},

	/**
	 * Determins if the given input is an Array.
	 * @param		{any}			obj 
	 * @returns		{boolean}
	 */
	array(
		obj:any
	): boolean {
		return Array.isArray(obj);
	},

	/**
	 * Determins if the given input is an Object.
	 */
	object(
		obj:any
	): boolean {
		if (
			typeof obj === 'object' &&
			!Array.isArray(obj) &&
			obj !== null
		) {
			return true;
		}
	
		else {
			return false;
		}
	},

	/**
	 * Determins if the given input is iterable.
	 */
	iterable(
		obj: any
	): boolean {  
		if (obj === null || obj === undefined) {
			return false;
		}
	
		return typeof obj[Symbol.iterator] === 'function';
	},

	/**
	 * Determins if the given input is a HTMLNode.
	 */
	node(
		obj:any
	): boolean {
		return (
			typeof Node === "object" ? obj instanceof Node : 
			obj && typeof obj === "object" && typeof obj.nodeType === "number" && typeof obj.nodeName==="string"
		);
	},

	/**
	 * Determins if the given input is a HTMLElement.
	 */
	element(
		obj:any
	): boolean {
		return (
			typeof HTMLElement === "object" ? obj instanceof HTMLElement : //DOM2
			obj && typeof obj === "object" && obj !== null && obj.nodeType === 1 && typeof obj.nodeName==="string"
		);
	},

	/**
	 * Determins if the given input is a Function.
	 */
	function(
		obj:any
	): boolean {
		return typeof obj === 'function';
	},

	/**
	 * Determins if the given input is a valid Object Key.
	 */
	validKey(
		obj:any
	): boolean {
		return is.string(obj) || is.int(obj);
	}
}