/**
 * Inhaltsverzeichnis
 * 	1. Imports
 * 	2. Scroll
 * 		2.1 Properties
 * 		2.2 Constructor
 * 		2.3 Constructor
 * 		2.4 Get Duration
 * 		2.5 Get Element Coordinates
 * 		2.6 To
 * 		2.7 To Top
 * 		2.8 Init
 * 
 */


// ==================================================
// MARK: 1. Imports
// --------------------------------------------------
import {header, body} from '../../module/element.ts';
import {is} from '../../module/type.ts';
const	$	=	jQuery;


// ==================================================
// MARK: 2. Scroll
// --------------------------------------------------
/**
 * This class handles all Scroll related tasks
 * @since		6.0.0.6
 * @version		1.0
 * @author		Elko Keil
 * @package		scroll
 */
export default class Scroll {
	// #=#=#=#=#=# 2.1 Properties #=#=#=#=#=#
		// #==== Initialised	| Static ====#
	/**	@type		The minimum Scroll position */
	public static	pageTop:number				= body.getBoundingClientRect().top;
	/**	@type		The maximum Scroll position */
	private static	minScrollY:number			= 0;
	/**	@type		The maximum Scroll position */
	private static	maxScrollY:number			= body.scrollHeight - window.innerHeight;
	/**	@type		Base duration of an animation in ms */
	private static	baseDuration:number			= 300;
	/**	@type		Speed Index of the Animation */
	private static	speed:number				= 1;
	/**	@type		Screen Height */
	private static	screenHeight:number			= window.innerHeight;


	// #=#=#=#=#=# 2.2 Constructor #=#=#=#=#=#
	private constructor() {
		// NON CONSTROCTING
	}


	// #=#=#=#=#=# 2.3 Get Distance #=#=#=#=#=#
	/**
	 * Retrieves the relative distance between the current scroll position and the given coordinates
	 * @param		x		X destination
	 * @param		y		Y destination
	 * @returns		Distance to the given coordinates
	 */
	private static getDistance(
		x:number,
		y:number
	): {x:number, y:number} {
		return {
			x: Math.abs(x - body.scrollLeft),
			y: Math.abs(y - body.scrollTop)
		}
	}


	// #=#=#=#=#=# 2.4 Get Duration #=#=#=#=#=#
	/**
	 * Retrieves the duration of the scroll animation based on the given position difference to the target
	 * @param		delta		The distance to the target
	 * @returns		The duration of the scroll animation in ms
	 */
	private static getDuration(
		delta: {x:number, y:number}
	): number {
		// #==== Calculate Delta ====#
		const duration	= this.baseDuration * delta.y / this.screenHeight;


		// #==== Set Minimum ====#
		if(duration < this.baseDuration) return this.baseDuration;


		// #==== Return ====#
		return duration;
	}


	// #=#=#=#=#=# 2.5 Get Element Coordinates #=#=#=#=#=#
	/**
	 * Retrieves the coordinates of an Element relative to the page
	 * @param		element		The Element to retrieve the coordinates from
	 * @returns		The coordinates of the Element
	 */
	private static getElementCoordinates(
		element:HTMLElement
	): {x:number, y:number} {
		// #==== Guard ====#
		if(!is.element(element)) {
			throw new Error('Scroll.to: The target needs to be a Number, HTMLElement or Object.');
		}


		// #==== Get Coords ====#
		const {left, top}	= element.getBoundingClientRect();
		return {
			x: left + body.scrollLeft,
			y: top + body.scrollTop
		};
	}


	// #=#=#=#=#=# 2.6 To #=#=#=#=#=#
	/**
	 * Scrolls to given coordinates or Element
	 * @param		target		The target to scroll to
	 * @param		y			The y coordinate to scroll to
	 */
	public static to(
		target:number|HTMLElement|Record<string, any>,
		y:number|undefined = undefined
	): void {
		// #==== Identefy Type ====#
		switch(true) {
			case is.number(target):
				// +---- Convert Type ----+
				target	=	{x: target};

				// +---- Set Y ----+
				if(!!y) {
					target.y	=	y;
				}
				break;

			case is.element(target):
				// +---- Convert Type ----+
				target		=	Scroll.getElementCoordinates(target);
				break;

			case is.object(target):
				// +---- Guard ----+
				if(!target.x && !target.y && !is.element(target.element)) {
					throw new Error('Scroll.to: The target object needs to have at least one coordinate.');
				}


				// +---- Element in Object ----+
				if(is.element(target.element)) {
					const coordinates	=	Scroll.getElementCoordinates(target.element);
					target				=	Object.assign(target, coordinates);
				}
				break;

			default:
				// +---- Fail ----+
				throw new Error('Scroll.to: The target needs to be a Number, HTMLElement or Object.');
		}


		// #==== Animation Options ====#
		// Set up the default animation options
		const options	= {
			duration: 0,
			easing: 'swing',
			queue: false,
		};


		// #==== Calculate Delta ====#
		// If smooth scrolling is enabled, calculate a smoother scroll duration
		if(!target.instant) {
			const delta			= this.getDistance(target.x, target.y);
			target.duration		??= this.getDuration(delta);

			options.duration	= target.duration / this.speed;
		}


		// #==== Target Destination ====#
		// Animate the windows scroll position
		const destination	=	{};
		if(target.x) destination.scrollLeft	=	target.x;
		if(target.y) destination.scrollTop	=	target.y;

		
		// #==== Offsets ====#
		// Add offsets to the scroll position
		target.offset	??=	{};

			// +---- Elements ----+
		if(target.offset.header ?? true) {
			destination.scrollTop	-=	(header? header.offsetHeight : 0);
		}

			// +---- Calculations ----+
		if(target.x && target.offset.x) destination.scrollLeft	+=	target.offset.x;
		if(target.y && target.offset.y) destination.scrollTop	+=	target.offset.y;


		// #==== Validate Range ====#
		// Adjust the scroll position to the minimum and maximum scroll position
		if(!!destination.scrollLeft && destination.scrollLeft < 0) destination.scrollLeft = 0;
		if(!!destination.scrollTop && !destination.scrollTop.inRange(this.minScrollY, this.maxScrollY)) {
			destination.scrollTop		=	destination.scrollTop.clamp(this.minScrollY, this.maxScrollY);
		}
			

		// #==== Run Animation ====#
		$(body).stop().animate(destination, options);
	}


	// #=#=#=#=#=# 2.7 To Top #=#=#=#=#=#
	/**
	 * Scrolls to the top of the page
	 */
	public static toTop() {
		Scroll.to({
			y: this.pageTop
		});
	}


	// #=#=#=#=#=# 2.8 Init #=#=#=#=#=#
	/**
	 * Initialises static values and events within the class
	 */
	public static __init(
	): void {
		// #==== On load ====#
		// Scroll to the hash target when the page loads
		if(window.location.hash) {
			const target	=	document.querySelector(window.location.hash);

			if(target) {
				this.to({
					element: target,
					instant: true,
				});
			}
		}
	}
}