/**
 * Inhaltsverzeichnis
 * 	1. Imports
 * 	2. Initialising
 * 		2.1 JS State
 * 		2.2 DOM State
 * 	3. Functions
 * 
 */


// ==================================================
// MARK: 1. Imports
// --------------------------------------------------
import { root, header } from './module/element.ts';
import SurfaceObserver from './class/observer/surface.js';
import Scroll from './class/scroll/main.ts';


// ==================================================
// MARK: 2. Initialising
// --------------------------------------------------
// #=#=#=#=#=# 2.1 JS State #=#=#=#=#=#
root.dataset.jsState	= 'active';


// #=#=#=#=#=# 2.2 DOM State #=#=#=#=#=#
window.addEventListener('DOMContentLoaded', () => {
	root.dataset.domState	= 'ready';
});


// ==================================================
// MARK: 3. Functions
// --------------------------------------------------
// #=#=#=#=#=# 3.1 Observe Elements #=#=#=#=#=#
new SurfaceObserver(header, {
	name: 'cvh-site-header'
});


// #=#=#=#=#=# 3.2 Initialise Scroll Helper #=#=#=#=#=#
Scroll.__init();
window.scrollCrontroller	=	Scroll;	// Export Scroll Controller