/**
 * Inhaltsverzeichnis
 * 	1. Default Elements
 * 		1.1 Root
 * 		1.2 Body
 * 		1.3 Header
 * 		1.4 Main
 * 		1.5 Footer
 * 
 */


/* ================================================== */
/* 1. Default Elements */
/* -------------------------------------------------- */
// #=#=#=#=#=# 1.0 Admin Bar #=#=#=#=#=#
export const adminbar	= document.getElementById('wpadminbar');


// #=#=#=#=#=# 1.1 Root #=#=#=#=#=#
export const root		= document.documentElement;


// #=#=#=#=#=# 1.2 Body #=#=#=#=#=#
export const body		= document.body;


// #=#=#=#=#=# 1.3 Header #=#=#=#=#=#
// @ts-expect-error
export const header		= document.body.children.find('header');


// #=#=#=#=#=# 1.5 Main #=#=#=#=#=#
// @ts-expect-error
export const banner		= document.body.children.find('#banner');


// #=#=#=#=#=# 1.5 Main #=#=#=#=#=#
// @ts-expect-error
export const main		= document.body.children.find('main');


// #=#=#=#=#=# 1.6 Footer #=#=#=#=#=#
// @ts-expect-error
export const footer		= document.body.children.find('footer');